import React from 'react'
import CreateShadowedFile from './createShadowedFile'
import './App.css'

const App = () => (
  <div className="App">
    <CreateShadowedFile />
  </div>
)
export default App
